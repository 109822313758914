var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    class: _vm.hasHighHourlyRate ? 'warn' : '',
    attrs: {
      "value": _vm.value,
      "rules": [].concat(_vm.rules, _vm.onlyInteger ? [_vm.formRules.isInteger(_vm.value)] : [_vm.formRules.isCommaSeparatedFloat(_vm.value)]),
      "autofocus": _vm.withAutofocus,
      "color": _vm.hasHighHourlyRate ? 'warning' : undefined,
      "hint": _vm.hasHighHourlyRate ? _vm.$t('form.validation.hasHighHourlyRate') : undefined,
      "persistent-hint": _vm.hasHighHourlyRate ? true : false
    },
    scopedSlots: _vm._u([_vm.hasHighHourlyRate ? {
      key: "append",
      fn: function fn() {
        return [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiAlert) + " ")])];
      },
      proxy: true
    } : null, _vm.hasHighHourlyRate ? {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "rounded-lg",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('confirm');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('form.confirm')) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners), [_vm._l(Object.keys(_vm.$slots), function (slot) {
    return _vm._t(slot, null, {
      "slot": slot
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }