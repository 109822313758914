var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "500px"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.hasCancelButtonFocus && null;
      }]
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._t("title")], 2), _c('v-card-text', [_c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [_c('v-row', [_c('v-col', [_vm._t("fields")], 2)], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "disabled": !_vm.state.isFormValid,
      "elevation": "0",
      "loading": _vm.isLoading,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onAdd();
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("form.".concat(_vm.isEditMode ? 'save' : 'add'))) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }