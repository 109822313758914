var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._g(_vm._b({
    ref: "autocompleteRef",
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.showApplyFilteredItemsIcon ? _vm.listeners.onApplyFilteredItems() : null;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var _vm$autocompleteRef, _vm$autocompleteRef2, _vm$autocompleteRef3, _vm$autocompleteRef4, _vm$autocompleteRef5;

        var item = _ref.item;
        return [(_vm$autocompleteRef = _vm.autocompleteRef) !== null && _vm$autocompleteRef !== void 0 && _vm$autocompleteRef.multiple ? _c('v-chip', {
          staticClass: "my-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s((_vm$autocompleteRef2 = _vm.autocompleteRef) !== null && _vm$autocompleteRef2 !== void 0 && _vm$autocompleteRef2.itemText ? item[_vm.autocompleteRef.itemText] : item) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.renderItem || (_vm$autocompleteRef3 = _vm.autocompleteRef) !== null && _vm$autocompleteRef3 !== void 0 && _vm$autocompleteRef3.itemText && ((_vm$autocompleteRef4 = _vm.autocompleteRef) === null || _vm$autocompleteRef4 === void 0 ? void 0 : _vm$autocompleteRef4.itemText) !== 'text' ? item[(_vm$autocompleteRef5 = _vm.autocompleteRef) === null || _vm$autocompleteRef5 === void 0 ? void 0 : _vm$autocompleteRef5.itemText] : item) + " ")])];
      }
    }, {
      key: "append",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.isActive && _vm.childrenToggle,
                  expression: "state.isActive && childrenToggle"
                }],
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.$emit('toggleWithChildren');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": _vm.showWithChildren ? 'primary' : ''
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiFileTree) + " ")])], 1)];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('misc.withChildren')) + " ")]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.showApplyFilteredItemsIcon,
                  expression: "state.showApplyFilteredItemsIcon"
                }],
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": _vm.listeners.onApplyFilteredItems
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiArrowLeftTop) + " ")])], 1)];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('misc.applyAllFilteredItems')) + " ")])];
      },
      proxy: true
    }])
  }, 'v-autocomplete', _vm.$attrs, false), Object.assign({}, _vm.$listeners, {
    'update:search-input': _vm.listeners.onSearchInput,
    'click:clear': _vm.listeners.onClear,
    blur: _vm.listeners.onReset,
    focus: _vm.listeners.onFocus
  })));
}
var staticRenderFns = []

export { render, staticRenderFns }