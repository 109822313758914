
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isEmail, isPhone, isRequired, isZipCode } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateSupplier, useUpdateSupplier } from '@/api/supplier'
import { useGetCorporateUnitsBasic } from '@/api/corporateUnit'

import { SupplierInput, SupplierOutput } from '@/api/types/supplier'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'AddEditSupplierDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    supplierToEdit: {
      type: Object as PropType<SupplierOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.supplierToEdit))

    const {
      exec: getCorporateUnitsBasic,
      data: corporateUnitsBasic,
      isLoading: isLoadingCorporateUnitsBasic,
    } = useGetCorporateUnitsBasic()
    getCorporateUnitsBasic()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.suppliers.form.name') as string)],
      },
      {
        value: 'phone1',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('contractData.suppliers.form.phone1') as string)],
      },
      {
        value: 'phone2',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('contractData.suppliers.form.phone2') as string)],
      },
      {
        value: 'emailCorporate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('contractData.suppliers.form.emailCorporate') as string)],
      },
      {
        value: 'address1',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address2',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressZipCode',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [(value: string) => isZipCode(value)],
      },
      {
        value: 'addressCity',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressCountry',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'corporateUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => corporateUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingCorporateUnitsBasic.value),
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<SupplierInput | SupplierOutput>(
      isEditMode.value ? cloneDeep(props.supplierToEdit) : ({} as SupplierInput)
    )

    const { createSupplier, isLoading: isLoadingCreateSupplier } = useCreateSupplier()

    async function onAdd(): Promise<void> {
      if (!isOfType<SupplierOutput>(form.value, 'id')) {
        form.value = convertCommaIntoDotInputWithForm(FORM_FIELDS, form.value)

        try {
          await createSupplier(form.value)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateSupplier, isLoading: isLoadingUpdateSupplier } = useUpdateSupplier()

    async function onEdit(): Promise<void> {
      if (isOfType<SupplierOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<SupplierOutput>(form.value)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateSupplier(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditSupplier = computed(() => isLoadingCreateSupplier.value || isLoadingUpdateSupplier.value)

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    watch(
      () => props.supplierToEdit,
      () => {
        if (props.supplierToEdit) {
          form.value = props.supplierToEdit

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditSupplier,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
