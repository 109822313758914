
import { defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'CommonAddEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup: (_, { emit }) => {
    const formElement = ref<HTMLFormElement | null>(null)

    const isFormValid = ref(true)

    const hasCancelButtonFocus = ref(false)

    function onAdd(): void {
      if (!isFormValid.value || hasCancelButtonFocus.value) return

      emit('add')
    }

    return reactive({
      formElement,
      state: {
        isFormValid,

        hasCancelButtonFocus,
      },
      listeners: {
        onAdd,
      },
    })
  },
})
