
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiArrowLeftTop, mdiFileTree } from '@mdi/js'
import { VAutocomplete } from 'vuetify/lib/components'

import { isOfType } from '@/utils/types/isOfType'
import { BasicEntity } from '@/api/types/misc'

export default defineComponent({
  name: 'CommonAutocomplete',
  inheritAttrs: false,
  props: {
    renderItem: {
      type: Boolean,
      default: false,
    },
    childrenToggle: {
      type: Boolean,
      default: false,
    },
    showWithChildren: {
      type: Boolean,
      default: false,
    },
  },
  setup: (_, { emit }) => {
    const autocompleteRef = ref<null | (typeof VAutocomplete & { filteredItems: unknown[]; multiple: boolean })>(null)

    const filteredItems = ref<unknown[]>([])

    function onSearchInput(): void {
      filteredItems.value = autocompleteRef.value?.filteredItems ?? []
    }

    const isApplyFilteredItemsIconVisible = ref(true)

    const showApplyFilteredItemsIcon = computed(() =>
      Boolean(autocompleteRef.value?.multiple && filteredItems.value.length && isApplyFilteredItemsIconVisible.value)
    )

    function onApplyFilteredItems(): void {
      emit(
        'input',
        filteredItems.value.map((item: unknown) => {
          if (isOfType<BasicEntity>(item, 'id')) {
            return item.id
          } else {
            item
          }
        })
      )

      isApplyFilteredItemsIconVisible.value = false
    }

    const isActive = ref(false)

    function onFocus(): void {
      isApplyFilteredItemsIconVisible.value = true

      isActive.value = true
    }

    function onReset(): void {
      isApplyFilteredItemsIconVisible.value = false

      filteredItems.value = []
      isActive.value = false
    }

    function onClear(): void {
      isApplyFilteredItemsIconVisible.value = false

      filteredItems.value = []
    }

    return reactive({
      autocompleteRef,
      icons: {
        mdiArrowLeftTop,
        mdiFileTree,
      },
      state: {
        filteredItems,
        showApplyFilteredItemsIcon,
        isApplyFilteredItemsIconVisible,
        isActive,
      },
      listeners: {
        onSearchInput,
        onApplyFilteredItems,
        onReset,
        onClear,
        onFocus,
      },
    })
  },
})
