import { cloneDeep } from 'lodash-es'

import { isOfTypeObject } from '@/utils/isOfTypeObject'

function mapBasicEntityToIdWithForm<T>(form: T, excludedProperties: string[] = []): T {
  const updatedForm = cloneDeep(form)

  Object.entries(updatedForm).forEach(([key, value]) => {
    if (!excludedProperties.includes(key)) {
      if (Array.isArray(value) && value[0]?.id) {
        updatedForm[key] = value.map((entry) => entry.id)
      } else {
        value && isOfTypeObject(value) && value?.id && (updatedForm[key] = value.id)
      }
    }
  })

  return updatedForm
}

export { mapBasicEntityToIdWithForm }
